import React, { useState, useCallback, useEffect } from "react";
import debounce from "lodash.debounce";

interface BlueprintSettingsPanelProps {
  audience: string;
  additionalDetails: string;
  saveBlueprintSettings: (audience: string, additionalDetails: string) => Promise<any>;
}

const BlueprintSettingsPanel: React.FC<BlueprintSettingsPanelProps> = ({ audience, additionalDetails, saveBlueprintSettings }) => {
  const [audienceValue, setAudienceValue] = useState(audience);
  const [additionalDetailsValue, setAdditionalDetailsValue] = useState(additionalDetails);

  // Update local state when props change
  useEffect(() => {
    setAudienceValue(audience);
    setAdditionalDetailsValue(additionalDetails);
  }, [audience, additionalDetails]);

  const debouncedSaveSettings = useCallback(
    debounce((newAudience: string, newAdditionalDetails: string) => {
      saveBlueprintSettings(newAudience, newAdditionalDetails);
    }, 500),
    [saveBlueprintSettings]
  );

  const handleAudienceChange = (newAudience: string) => {
    setAudienceValue(newAudience);
    debouncedSaveSettings(newAudience, additionalDetailsValue);
  };

  const handleAdditionalDetailsChange = (newAdditionalDetails: string) => {
    setAdditionalDetailsValue(newAdditionalDetails);
    debouncedSaveSettings(audienceValue, newAdditionalDetails);
  };

  return (
    <div style={{
      height: "100vh",
      overflowY: "scroll",
    }}>
      <h1>Edit Settings</h1>

      <p className="blueprint-section-prop">
        <b style={{ textAlign: "left" }}>Audience:</b>
      </p>
      <div style={{ padding: "0 16px" }}>
        <textarea
          value={audienceValue}
          onChange={(e) => handleAudienceChange(e.target.value)}
          placeholder="Describe your target audience..."
          rows={8}
          cols={30}
          className="feedback-textarea-input"
          style={{ marginBottom: "20px" }}
        />
      </div>

      <p className="blueprint-section-prop">
        <b style={{ textAlign: "left" }}>Additional Details:</b>
      </p>
      <div style={{ padding: "0 16px" }}>
        <textarea
          value={additionalDetailsValue}
          onChange={(e) => handleAdditionalDetailsChange(e.target.value)}
          placeholder="Add examples, writing style, preamble, prompt, or other notes..."
          rows={24}
          cols={30}
          className="feedback-textarea-input"
          style={{ marginBottom: "20px" }}
        />
      </div>
    </div>
  );
};

export default BlueprintSettingsPanel;
