import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import { BlueprintSection } from "./Onboarding/Types";
import {
  BLUEPRINT_SECTION_DESCRIPTIONS,
  BLUEPRINT_SECTION_EXAMPLES,
  SECTION_FORMAT_TO_DISPLAY_NAME
} from "./Onboarding/Constants";

export interface BlueprintSectionPanelProps {
  sections: BlueprintSection[],
  index: number;
  updateSection: (index: number, updatedSection: Partial<BlueprintSection>) => void;
}

const BlueprintSectionPanel: React.FC<BlueprintSectionPanelProps> = ({ sections, index, updateSection }) => {
  const section = sections[index];

  const [title, setTitle] = useState(section?.title);
  const [contentType, setContentType] = useState(section?.content_type);
  const [format, setFormat] = useState(section?.format);
  const [instructions, setInstructions] = useState(section?.instructions || "");

  useEffect(() => {
    setTitle(section?.title);
    setContentType(section?.content_type);
    setFormat(section?.format);
    setInstructions(section?.instructions || "");
  }, [section]);

  const handleContentTypeChange = (newContentType: string) => {
    setContentType(newContentType);
    const formatTypes = Object.keys(BLUEPRINT_SECTION_EXAMPLES[newContentType]);
    const newFormat = formatTypes[0];
    setFormat(newFormat);

    updateSection(index, { content_type: newContentType, format: newFormat })
  };

  const handleFormatChange = (newFormat: string) => {
    setFormat(newFormat);

    updateSection(index, { format: newFormat });
  };

  const debouncedUpdateSection = useCallback(
    debounce((newTitle: string) => {
      updateSection(index, { title: newTitle });
    }, 500), // delay before calling updateSection
    [index, section, updateSection] // memoized unless these values change
  );

  const handleTitleChange = (newTitle: string) => {
    setTitle(newTitle);

    debouncedUpdateSection(newTitle);
  };

  const debouncedUpdateInstructions = useCallback(
    debounce((newInstructions: string) => {
      updateSection(index, { instructions: newInstructions });
    }, 500),
    [index, section, updateSection]
  );

  const handleInstructionsChange = (newInstructions: string) => {
    setInstructions(newInstructions);

    debouncedUpdateInstructions(newInstructions);
  };

  let allContentTypes: string[] = [];
  let formatTypes: string[] = [];
  let descriptionText;
  let example_html;
  if (contentType && format) {
    allContentTypes = Object.keys(BLUEPRINT_SECTION_EXAMPLES);
    formatTypes = Object.keys(BLUEPRINT_SECTION_EXAMPLES[contentType]);
    descriptionText = BLUEPRINT_SECTION_DESCRIPTIONS[contentType][format]
    example_html = BLUEPRINT_SECTION_EXAMPLES[contentType][format];
  }

  return (
    <div style={{
      height: "100vh",
      overflowY: "scroll",
    }}>
      <h1>Edit Section</h1>

      <p className="blueprint-section-prop">
        <b style={{ textAlign: "left" }}>Title:</b>
        <input
          type="text"
          value={title}
          onChange={(e) => handleTitleChange(e.target.value)}
          style={{
            width: "280px",
            padding: "8px 12px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            backgroundColor: "#ffffff",
            fontSize: "14px",
            color: "#333",
            cursor: "text",
            outline: "none",
            transition: "all 0.2s ease",
            boxSizing: "border-box",
          }}
        />
      </p>

      <p className="blueprint-section-prop">
        <b>Content type:</b>
        <select
          value={contentType}
          onChange={(e) => handleContentTypeChange(e.target.value)}
          className="blueprint-section-dropdown"
        >
          { allContentTypes.map((contentType) => (
            <option key={contentType} value={contentType}>
              {contentType}
            </option>
          ))}
        </select>
      </p>

      <p className="blueprint-section-prop">
        <b>Format:</b>
        <select
          value={format}
          onChange={(e) => handleFormatChange(e.target.value)}
          className="blueprint-section-dropdown"
        >
          { formatTypes.map((formatType) => (
            <option key={formatType} value={formatType}>
              {SECTION_FORMAT_TO_DISPLAY_NAME[formatType] || formatType}
            </option>
          ))}
        </select>
      </p>

      <p className="blueprint-section-prop">
        <b style={{ textAlign: "left" }}>Instructions:</b>
      </p>
      <div style={{ padding: "0 16px" }}>
        <textarea
          value={instructions}
          onChange={(e) => handleInstructionsChange(e.target.value)}
          placeholder="Add instructions for this section..."
          rows={6}
          cols={30}
          className="feedback-textarea-input"
          style={{ marginBottom: "0px" }}
        />
      </div>

      <p className="blueprint-section-prop">
        <b style={{ textAlign: "left" }}>Examples:</b>
      </p>
      <p className="blueprint-section-prop" style={{ textAlign: "left" }}>
        {descriptionText}
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html: example_html,
        }}
      />
    </div>
  );
}

export default BlueprintSectionPanel;
