import axios from "axios";
import { getApiUrlWithVersion } from "./environmentService";
import { getAuthUser, getCurrentUserEmail } from "./authService";
import { BlueprintSection, DetailCheckboxOption, OnboardingFormData } from "../components/Onboarding/Types";
import {
  COMMUNITY_CONTENT_CIO, PREPPED_SAMPLE_DATA,
  SAMPLE_EVENTS_CIO,
  SAMPLE_JOBS_CIO,
  SAMPLE_NEWS_CIO
} from "../components/Onboarding/SampleContent";

export class OnboardingService {
  /**
   * Generate a sample newsletter edition with the given context.
   *
   * Returns the ID of the newly created newsletter edition.
   */
  async generateSampleNewsletterEdition(context: object): Promise<string> {
    const response = await axios.post(
      `${getApiUrlWithVersion()}/onboarding/generate_sample_newsletter_edition`,
      {context},
      {headers: this.getAuthHeaders()},
    );
    console.log("Generated sample newsletter edition:", response.data);
    return response.data.newsletter_edition_id;
  }

  // write me a function to save onboarding data. I dont want it to block
  async saveOnboardingData(
    formData: OnboardingFormData,
    generateBlueprint: boolean,
    scrapeBrandAssets: boolean,
  ): Promise<string> {
    console.log("Saving onboarding data");
    const response = await axios.post(
      `${getApiUrlWithVersion()}/onboarding/save_onboarding_data`,
      {
        ...formData,
        generateBlueprint,
        scrapeBrandAssets,
      },
      {headers: this.getAuthHeaders()},
    ).then(

      (response) => {
        console.log("Saved onboarding data:", response.data);
        return response.data;
      }
    )
    return response.data;
  }

  // TODO all these functions will need to go async later & dynmically figure out data based on website + reader
  generateSampleJobs(): DetailCheckboxOption[] {
    const email = getCurrentUserEmail();

    if (email && email in PREPPED_SAMPLE_DATA) {
      return PREPPED_SAMPLE_DATA[email].jobs;
    }

    return SAMPLE_JOBS_CIO;
  }

  generateSampleEvents(): DetailCheckboxOption[] {
    const email = getCurrentUserEmail();
    console.log("In generateSampleEvents", email);

    if (email && email in PREPPED_SAMPLE_DATA) {
      return PREPPED_SAMPLE_DATA[email].events;
    }

    return SAMPLE_EVENTS_CIO;
  }

  generateSampleCommunityContent(): DetailCheckboxOption[] {
    const email = getCurrentUserEmail();

    if (email && email in PREPPED_SAMPLE_DATA) {
      return PREPPED_SAMPLE_DATA[email].communityContent;
    }

    return COMMUNITY_CONTENT_CIO;
  }

  generateSampleNews(): DetailCheckboxOption[] {
    const email = getCurrentUserEmail();

    if (email && email in PREPPED_SAMPLE_DATA) {
      return PREPPED_SAMPLE_DATA[email].news;
    }

    return SAMPLE_NEWS_CIO;
  }

  async getOnboardingBlueprint() {
    const response = await axios.get(
      `${getApiUrlWithVersion()}/onboarding/blueprint`,
      { headers: this.getAuthHeaders() },
    );

    return response.data;
  }

  async saveOnboardingBlueprint(sections: BlueprintSection[], audience?: string, additionalDetails?: string) {
    const requestBody = {
      sections,
      audience,
      additionalDetails,
    };

    const response = await axios.put(
      `${getApiUrlWithVersion()}/onboarding/blueprint`,
      requestBody,
      { headers: this.getAuthHeaders() },
    );

    return response.data;
  }

  async getOnboardingInstantSample(afterTimestamp: string) {
    const response = await axios.get(
      `${getApiUrlWithVersion()}/onboarding/instant_sample`, {
        headers: this.getAuthHeaders(),
        params: { created_after: afterTimestamp },
      },
    );

    return response.data;
  }

  async generateOnboardingInstantSample(audienceOfOne: string, companyWebsite: string) {

    const url = new URL(companyWebsite);
    const domain = url.hostname.replace(/^www\./, '');

    const response = await axios.post(
      `${getApiUrlWithVersion()}/onboarding/instant_sample`,
      { audienceOfOne, domain },
      { headers: this.getAuthHeaders() },
    );

    return response.data;
  }

  private getAuthHeaders(): object {
    return {
      Authorization: getAuthUser()?.accessToken,
    };
  }
}

const onboardingService = new OnboardingService();
export default onboardingService;
