import React, { useState } from "react";
import campaignService from "../services/campaignService";

export interface SendTestEmailModalProps {
  newsletterEditionId: string | undefined;
  isLoading: boolean;
  html: string;
  title: string;
  onClose: () => void;
  setSendTestEmailSuccess: (success: boolean) => void;
}

const SendTestEmailModal: React.FC<SendTestEmailModalProps> = ({
  newsletterEditionId,
  isLoading,
  html,
  title,
  onClose,
  setSendTestEmailSuccess,
}) => {
  const [toAddress, setToAddress] = useState("");

  const sendTestEmail = () => {
    campaignService.sendTestNewsletterEditionEmail(
      newsletterEditionId!,
      toAddress,
      html,
      title
    ).then(() => {
      onClose();
      setSendTestEmailSuccess(true);
    });
  };

  return (
    <div
      className="primary-input-container"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <h2>Send test email</h2>
      {
        isLoading ? <p>Loading...</p> :
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}>
            <label htmlFor="to-address">To:</label>
            <input
              id="to-address"
              type="email"
              value={toAddress}
              onChange={e => setToAddress(e.target.value)}
              placeholder="example@example.com"
              className="url-input"
            />
            <button
              className="primary-button"
              onClick={sendTestEmail}
              disabled={!newsletterEditionId || !toAddress}
            >
              Send
            </button>
            <button className="tertiary-button" onClick={onClose}>
              Close
            </button>
          </div>
      }
    </div>
  );
};

export default SendTestEmailModal;
